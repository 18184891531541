<template>
  <!-- popup for adding devices for individual users  -->
  <v-dialog overflow-hidden persistent v-model="addRouterDialog" max-width="600px">
    <DialogCard v-if="addRouterDialog" :modalIcon="'plus'" :modalTitle="'Add Device'">
      <template #header>
        <v-btn icon x-small @click="close_add_device_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-window v-model="formPage">
          <v-window-item :value="0">
            <div class="pa-2">
              <v-select label="Select Model" v-model="modelSelect" :items="mixinModelList()" outlined required dense />
              <div style="display: grid; grid-template-columns: 1fr 1fr 2fr">
                <v-text-field dense class="remove-border" label="model" v-model="modelSelect.model" outlined readonly />
                <v-select label="TAC" v-model="TACNumber" dense :items="modelSelect.TAC" outlined />
                <v-text-field outlined :disabled="isLoading" class="ml-4" maxlength="7" label="Device Serial Number*" dense v-model="deviceSerialNumber" @keyup.enter="verify_imei()" />
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="1">
            <div class="pa-2" style="display: grid; grid-template-columns: 1fr 1fr">
              <v-text-field label="Device Name" dense v-model="deviceName" :rules="[(val) => val.length <= 15 || 'Max Length(15)']" maxlength="15" outlined @keyup.enter="next()" />
              <v-text-field label="Device Description" dense class="ml-2" :rules="[(val) => val.length <= 50 || 'Max Length(50)']" maxlength="50" v-model="description" outlined @keyup.enter="next()" />
              <v-select label="License Region" v-model="licenseRegion" :items="['Korea', 'USA']" outlined required dense />
              <v-select class="ml-2" label="Partner Name" v-model="partnerName" item-text="label" item-value="value" :items="partnerList" outlined required dense />
              <v-select label="Carrier" v-model="carrierInfo" item-text="label" item-value="value" :items="carrierList" outlined required dense />
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <div class="pa-2">
              <h2 class="mb-4">Restart {{ modelSelect.model }}</h2>
              <div>
                <b> 1.Power down device.</b>
                <br />
                <b> 2.Power on device.</b>
                <v-img height="300px" width="300px" src="@/assets/RG2Powercycle.png"> </v-img>
              </div>
              <!-- <div v-if="modelSelect == 'FX20'">
                <v-row>
                  <v-col cols="6">
                    <v-img class="ml-n4" height="300px" width="300px" src="@/assets/FX20InternetConnection.png"> </v-img>
                    Make sure your device has an internet connection.
                    <br />Recommend restarting your modem.
                  </v-col>
                  <v-col cols="6">
                    <v-img class="ml-n4" height="300px" width="300px" src="@/assets/FX20-Powercycle.png"> </v-img>
                    1.Power down device.
                    <br />
                    2.Power on device.
                  </v-col>
                </v-row>
              </div> -->
            </div>
          </v-window-item>
        </v-window>
      </template>
      <template #footer>
        <v-window v-model="formPage">
          <v-window-item :value="0">
            <div class="d-flex">
              <v-btn :loading="isLoading" @click="verify_imei()" class="button tabledata--text">Verify</v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="1">
            <div class="d-flex">
              <v-btn class="button tabledata--text mr-2" @click="formPage = 0">Back</v-btn>
              <v-btn class="button tabledata--text" @click="next()">Next</v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div class="d-flex">
              <v-btn class="button tabledata--text mr-2" @click="formPage = 1">Back</v-btn>
              <v-btn class="button tabledata--text" :loading="isLoading" @click="finalize_adding_device()">Finalize</v-btn>
            </div>
          </v-window-item>
        </v-window>
      </template>
    </DialogCard>
  </v-dialog>
</template>
<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { getCarrierList, getPartnerList, checkDeviceExists, addNewDevice } from "@/services";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js"
import { mapGetters, mapMutations } from "vuex";

export default {
  mixins: [mixin_list_table_utils],
  components: {
    DialogCard,
  },
  props: {
    addRouterDialog: Boolean,
  },
  data() {
    return {
      modelSelect: this.mixinModelList()[0].value,
      TACNumber: "",
      deviceSerialNumber: "",
      formPage: 0,
      isLoading: false,

      partnerName: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
      licenseRegion: "USA",
      description: "",
      deviceName: "",
      carrierInfo: "TMOBILE",

      newRouterId: "",

      partnerList: [],
      carrierList: [],
    };
  },

  watch: {
    addRouterDialog: {
      handler(newVal) {
        // reinitialize the form when it is closed
        if (!newVal) {
          this.formPage = 0;
          this.modelSelect = this.mixinModelList()[0].value;
          this.deviceSerialNumber = "";
          this.deviceName = "";
          this.description = "";
          this.licenseRegion = "USA";
          this.partnerName = "c67eae2d-c536-4d57-9709-8bb3b77cafbd";
          this.carrierInfo = "TMOBILE";
        } else {
          this.getPartners();
          this.getCarriers();
        }
      },
      immediate: true,
    },
    modelSelect: {
      handler(){
        this.TACNumber = this.modelSelect.TAC[0];
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    //query to fetch partners list
    async getPartners() {
      this.partnerList = [];
      try {
        const response = await getPartnerList();
        response.forEach((element) => {
          this.partnerList.push({ label: element.user_full_name, value: element.user_id });
        });
      } catch (error) {}
    },

    //method to fetch the carrier list
    async getCarriers() {
      this.carrierList = [];
      try {
        const response = await getCarrierList();
        response.Items.forEach((element) => {
          this.carrierList.push({ label: element.carrier_type, value: element.carrier_type });
        });
      } catch (err) {}
    },

    //method invokes on close action
    close_add_device_dialog() {
      this.$emit("close");
    },

    //method to validate the data
    verify_imei() {
      if (!this.deviceSerialNumber) return this.triggerSnackBar("error", "Please enter a serial number");
      if (this.deviceSerialNumber.length !== 7) return this.triggerSnackBar("error", "Serial number must be 7 digits long");
      if (!this.isValidIMEI(this.TACNumber + this.deviceSerialNumber)) return this.triggerSnackBar("error", "Serial number number is invalid");
      this.checkDeviceIMEI();
    },

    //mutation call for checking the device exits or not
    async checkDeviceIMEI() {
      this.isLoading = true;
      let routerId = `${this.modelSelect.model}/${this.TACNumber + this.deviceSerialNumber}`;
      try {
        this.newRouterId = await checkDeviceExists(routerId);
        this.triggerSnackBar("success", "Verified Successfully!");
        this.formPage = 1;
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isLoading = false;
      }
    },

    //method to validate the empty fields
    next() {
      if (this.deviceName == "" || this.description == "" || this.licenseRegion == "") return this.triggerSnackBar("error", "Please Provide Mandatory Details");
      this.formPage = 2;
    },

    //mutation call for adding the new devices
    async finalize_adding_device() {
      this.isLoading = true;
      let data = {
        router_id: this.newRouterId.split("/").join(""),
        user_id: this.getterGetUserInfo.user.user_id,
        device_name: this.deviceName,
        router_description: this.description,
        partner_id: this.partnerName,
        licnese_country: this.licenseRegion,
        carrier_info: this.carrierInfo,
        imei_number: this.TACNumber + this.deviceSerialNumber,
      };
      try {
        const response = await addNewDevice(data);
        this.triggerSnackBar("success", "Added Succcesfully");
        this.$emit("close");
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isLoading = false;
      }
    },

    sumDig(n) {
      let a = 0;
      while (n > 0) {
        a = a + (n % 10);
        n = parseInt(n / 10, 10);
      }
      return a;
    },

    isValidIMEI(n) {
      // Converting the number into
      // String for finding length
      let s = n.toString();
      let len = s.length;

      if (len != 15) return false;

      let sum = 0;
      for (let i = len; i >= 1; i--) {
        let d = n % 10;

        // Doubling every alternate digit
        if (i % 2 == 0) d = 2 * d;

        // Finding sum of the digits
        sum += this.sumDig(d);
        n = parseInt(n / 10, 10);
      }

      return sum % 10 == 0;
    },
  },
};
</script>

<style scoped>
.v-input.remove-border >>> fieldset {
  border: none;
  border-width: 0px;
}
</style>
