<template>
  <div>
    <!-- tabs to diaplay the valid and invalid data -->
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>

          <v-tab>Valid</v-tab>
          <v-tab-item>
            <v-data-table dense :headers="validHeaders" :items="validData" :items-per-page="20" class="elevation-1 dtwidth"></v-data-table>
            <v-btn class="ma-4 white--text" rounded color="primary" @click="submit_mac_address_form()" :loading="isSubmitting" :disabled="invalidData.length > 0 || validData.length === 0">Submit</v-btn>
          </v-tab-item>

          <v-tab>Invalid</v-tab>
          <v-tab-item>
            <v-data-table dense :headers="validHeaders" :items="invalidData" hide-default-footer class="elevation-0 dtwidth"></v-data-table>
          </v-tab-item>

        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { registerDeviceDefaultMACAddress } from "@/services";

var AWS = require("aws-sdk");
export default {
  props: [ "invalidData", "validData"],
  data() {
    return {
      tab: 0,
      isSubmitting: false,
      registerDeviceList: [],
      validHeaders: [
        { text: "IMEI", value: "IMEI", class: "headerColor white--text", sortable: true },
        { text: "MAC Address", value: "MacID1", class: "headerColor white--text", sortable: true },
        { text: "Model", value: "Model", class: "headerColor white--text", sortable: true }
      ],
    };
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on add event
    async submit_mac_address_form() {
      this.convertListForSubmission();
      if(this.validData.length === 0) return this.triggerSnackBar("error", "You must upload at least 1 device.");
      if (this.invalidData.length > 0) return this.triggerSnackBar("error", "There are some invalid items, clear up the errors.");

      const s3Bucket = new AWS.S3({ region: "us-east-1", accessKeyId: this.getterGetUserInfo.s3details.accessKey, secretAccessKey: this.getterGetUserInfo.s3details.secretAccessKey});

      const subKey = this.getterGetUserInfo.user.customer_id + Date.now() + ".Json";
      const params = { Bucket: this.getterGetUserInfo.s3details.bucket, Key: subKey, ACL: "public-read", Body: JSON.stringify(this.registerDeviceList) };

      this.isSubmitting = true;
      try{
        const fileUpload = await s3Bucket.putObject(params).promise();
        const response = await registerDeviceDefaultMACAddress(subKey);
        this.$router.push("/Home/SuperBusinessDeviceList");
      } catch(error) {
        this.triggerSnackBar("error", error);
      } finally {
        this.isSubmitting = false;
      }
    },
    convertListForSubmission() {
      this.registerDeviceList = [];
      this.validData.forEach((element) => {
        this.registerDeviceList.push({
          router_id: element.IMEI != undefined ? element.IMEI : null,
          mac_address: element.MacID1 != undefined ? element.MacID1 : null,
        });
      });
    },
  },
};
</script>

<style scoped>
.invalid-field {
  color: #e53935;
}
</style>