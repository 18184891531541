<template>
  <div style="display: grid; height: 100%;">
    <v-card-text class="pa-6">
      <v-form ref="form">
        <div style="font-size: 14px">Enter the Activation Code</div>
        <v-text-field outlined dense hide-details label="Code" v-model="activationCode" maxlength="6" :disabled="isLoading" @keyup.enter="create_account()" />
        <div class="ml-1" style="font-size: 10px">*Enter the Activation Code sent to {{ signUpEmail }}.</div>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-btn @click="cancel_sign_up()" :disabled="isLoading" outlined class="blue--text">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="button tabledata--text mr-2" :disabled="isLoading" @click="back()">Back</v-btn>
      <v-btn class="button tabledata--text" :loading="isLoading" @click="create_account()">Finalize</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from 'axios';
import { Auth } from "aws-amplify";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {},
  mixins: [mixin_routing_utils, mixin_form_validation_utils],
  data() {
    return {
      isLoading: false,
      activationCode: "",
      userDetailObject: {},
      customerDetails: {}
    };
  },
  computed: {
    ...mapGetters(["getterGetSignUpWindowPage", "getterGetFirstName", "getterGetLastName", "getterGetSignUpEmail", "getterGetCountryCode", "getterGetSignUpPassword", "getterGetDeviceName", "getterGetDeviceDescription", "getterGetCarrierInfo", "getterGetSelectedModel", "getterGetTACNumber", "getterGetDeviceSerialNumber"]),
    windowPage: {
      get() {
        return this.getterGetSignUpWindowPage;
      },
      set(value) {
        this.mutationSetSignUpWindowPage(value);
      },
    },
    firstName: {
      get() {
        return this.getterGetFirstName;
      }
    },
    lastName: {
      get() {
        return this.getterGetLastName;
      }
    },
    signUpEmail: {
      get() {
        return this.getterGetSignUpEmail;
      }
    },
    countryCode: {
      get() {
        return this.getterGetCountryCode;
      }
    },
    signUpPassword: {
      get() {
        return this.getterGetSignUpPassword;
      }
    },
    deviceName: {
      get() {
        return this.getterGetDeviceName;
      }
    },
    deviceDescription: {
      get() {
        return this.getterGetDeviceDescription;
      }
    },
    carrierInfo: {
      get() {
        return this.getterGetCarrierInfo;
      }
    },
    selectedModel: {
      get() {
        return this.getterGetSelectedModel;
      }
    },
    TACNumber: {
      get() {
        return this.getterGetTACNumber;
      },
    },
    deviceSerialNumber: {
      get() {
        return this.getterGetDeviceSerialNumber;
      }
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem", "mutationSetSignUpWindowPage", "mutationSetLicenseInformation"]),
    ...mapActions(["actionCreateCustomer"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back(){
      this.windowPage = 2;
    },
    async create_account() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Fill in the mandatory fields.");
      this.isLoading = true;
      try {
        await this.validateActivationCode();
        await this.createUser();
        await this.assignDeviceToUser();
        this.triggerSnackBar("success", "Account Created Successfully!!!");
        this.windowPage = 4;
      } catch(error) {
        this.triggerSnackBar("error", error);
      } finally {
        this.isLoading = false;
      }
    },
    // Note that the sign up form reset action will be performed in router gaurd
    // Whenever the user returns from /SignUp page, the router guard will automatically reset the signup vuex states
    cancel_sign_up() {
      this.mixinChangeRoute("/");
    },

    async validateActivationCode() {
      const data =  {
        command: "verfiyingEmailAndCreateUser",
        user_first_name: this.firstName,
        user_last_name: this.lastName,
        user_email_id: this.signUpEmail.toLowerCase(),
        user_country_code: this.countryCode,
        verification_code: this.activationCode,
        user_created_from: "PORTAL",
      }

      const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/verfiying_email_and_create_user", headers: { "Content-Type": "application/json" }, data });
      if(response.data.errorType === "Error") throw new Error(response.data.errorMessage);

      // This is user object that is created in response to entering in the activation code.
      // It includes unique user_id that will be used for creating Stripe User
      this.userDetailObject = response.data.user;
    },

    async createUser() {
      const data = {
        command: "createUserParamsCognito",
        user_email_id: this.signUpEmail.toLowerCase(),
        user_password: this.signUpPassword
      }

      const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/create_user_params_cognito", headers: { "Content-Type": "application/json" }, data });
      if(response.data.errorType === "Error") throw new Error(response.data.errorMessage);
      // sign in step is required for the create customer action below to be valid
      // stripe action requires the user to be logged in
      await Auth.signIn(this.signUpEmail.toLowerCase(), this.signUpPassword);
      const customer = await this.actionCreateCustomer(this.userDetailObject.user_id);
      this.customerDetails = JSON.parse(customer.create_stripe_customer)
    },

    async assignDeviceToUser() {
      const data = {
        command: "individalBulkUploadRouter",
        // User Info
        acc_type: "individual",
        user_id: this.userDetailObject.user_id,
        customer_id: this.customerDetails.id,
        user_email_id: this.signUpEmail.toLowerCase(),
        name: `${this.firstName} ${this.lastName}`,
        licnese_country: this.countryCode, // Yes, "licnese" is spelled wrong and apparently, this is somehow correct.
        // Device Info
        router_id: this.selectedModel.model + this.TACNumber + this.deviceSerialNumber,
        imei_number: this.TACNumber + this.deviceSerialNumber,
        device_name: this.deviceName,
        router_description: this.deviceDescription,
        carrier_info: this.carrierInfo,
        partner_id: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
      }
      const response = await axios({ method: "POST", url: process.env.VUE_APP_MDM_ROUTER_API + "/individal_bulk_upload_router", headers: { "Content-Type": "application/json" }, data: data });
      if(response.data.errorType === "Error") throw new Error(response.data.errorMessage);
      this.mutationSetLicenseInformation(response.data.license);
    }

  },
};
</script>

<style scoped>
</style>
